import React from 'react';
import '../styles/style.css';

const StepTwo = ({ prevStep, handleChange, handleSubmit, values, onKeyPress }) => {
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    handleChange(name)({ target: { value: checked } });
  };

  return (
    <>
      {/* Informative text */}
      <p className="text-sm mb-3 text-base-content text-center">
        This information is purely to help our model provide more accurate answers and is in line with our{' '}
        <a href="" className="link link-primary">privacy policy</a>
      </p>
      
      <div className="flex flex-col gap-4">
        <input
          type="text"
          placeholder="Company Name *"
          onChange={handleChange('company')}
          value={values.company}
          className="input input-bordered w-full text-base-content"
          onKeyPress={onKeyPress}
        />
        <input
          type="text"
          placeholder="Industry"
          onChange={handleChange('industry')}
          value={values.industry}
          className="input input-bordered w-full text-base-content"
          onKeyPress={onKeyPress}
        />
        <input
          type="number"
          placeholder="Number of Employees"
          onChange={handleChange('employees')}
          value={values.employees}
          className="input input-bordered w-full text-base-content"
          onKeyPress={onKeyPress}
        />
        <input
          type="text"
          placeholder="Revenue"
          onChange={handleChange('revenue')}
          value={values.revenue}
          className="input input-bordered w-full text-base-content"
          onKeyPress={onKeyPress}
        />

        {/* Checkbox input */}
        <div className="form-control">
          <label className="label cursor-pointer">
            <span className="label-text text-base-content">I agree to receive marketing communications.</span>
            <input
              type="checkbox"
              name="marketingConsent"
              checked={values.marketingConsent}
              onChange={handleCheckboxChange}
              className="checkbox"
            />
          </label>
        </div>

        {/* Navigation buttons */}
        <div className="flex justify-between">
          <button onClick={prevStep} className="btn btn-outline">Back</button>
          <button onClick={handleSubmit} className="btn btn-primary">Sign Up</button>
        </div>
      </div>
    </>
  );
};

export default StepTwo;