import React from 'react';
import clsx from 'clsx';

// Define badge styles with night mode support
const badgeVariants = {
  default: 'bg-primary text-white dark:bg-primary-dark dark:text-white',
  secondary: 'bg-secondary text-white dark:bg-secondary-dark dark:text-white',
  outline: 'border border-gray-300 text-gray-700 dark:border-gray-500 dark:text-white',
};

const Badge = ({ variant = 'default', className, ...props }) => {
  return (
    <div
      className={clsx(
        'inline-flex items-center px-2.5 py-0.5 rounded-md text-xs font-semibold',
        badgeVariants[variant],
        className
      )}
      {...props}
    />
  );
};

export { Badge };