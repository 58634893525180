import React from 'react';
import clsx from 'clsx';

// Card container with dark mode support
const Card = React.forwardRef(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={clsx('rounded-xl border bg-white dark:bg-gray-800 shadow-md dark:border-gray-600', className)}
    {...props}
  />
));
Card.displayName = 'Card';

// Card header
const CardHeader = React.forwardRef(({ className, ...props }, ref) => (
  <div ref={ref} className={clsx('p-6 flex flex-col space-y-1.5', className)} {...props} />
));
CardHeader.displayName = 'CardHeader';

// Card title with dark mode support
const CardTitle = React.forwardRef(({ className, ...props }, ref) => (
  <h3 ref={ref} className={clsx('font-semibold text-xl', className)} {...props} />
));
CardTitle.displayName = 'CardTitle';

// Card description with dark mode support
const CardDescription = React.forwardRef(({ className, ...props }, ref) => (
  <p ref={ref} className={clsx('text-sm text-gray-600 dark:text-gray-300', className)} {...props} />
));
CardDescription.displayName = 'CardDescription';

// Card content
const CardContent = React.forwardRef(({ className, ...props }, ref) => (
  <div ref={ref} className={clsx('p-6 pt-0', className)} {...props} />
));
CardContent.displayName = 'CardContent';

// Card footer
const CardFooter = React.forwardRef(({ className, ...props }, ref) => (
  <div ref={ref} className={clsx('p-6 pt-0 flex items-center', className)} {...props} />
));
CardFooter.displayName = 'CardFooter';

export { Card, CardHeader, CardTitle, CardDescription, CardContent, CardFooter };