// src/ProtectedRoute.js

import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from './AuthContext'; // Import AuthContext

const ProtectedRoute = ({ children }) => {
  const { currentUser } = useContext(AuthContext); // Use context

  if (!currentUser) {
    // Redirect to the login page if not logged in
    return <Navigate to="/login" replace />;
   }

  return children; // Render children if the user is authenticated
};

export default ProtectedRoute;
