import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import { db } from './firebaseConfig';
import { collection, query, where, onSnapshot, updateDoc, doc } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { getAuth, signOut, updatePassword } from 'firebase/auth';

const Account = () => {
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [userData, setUserData] = useState({});
  const [editMode, setEditMode] = useState({});
  const [editedValues, setEditedValues] = useState({});
  const [newPassword, setNewPassword] = useState('');
  const [changePasswordMode, setChangePasswordMode] = useState(false);

  useEffect(() => {
    if (currentUser?.uid) {
      const usersRef = collection(db, 'users');
      const q = query(usersRef, where('uid', '==', currentUser.uid));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        if (!querySnapshot.empty) {
          const userDoc = querySnapshot.docs[0];
          setUserData({
            docId: userDoc.id,
            ...userDoc.data()
          });
          setEditedValues(userDoc.data());
        } else {
          console.log('No such document!');
        }
      });
      return () => unsubscribe();
    }
  }, [currentUser]);

  const toggleEdit = (field) => {
    setEditMode({ ...editMode, [field]: !editMode[field] });
  };

  const handleInputChange = (field, value) => {
    setEditedValues({ ...editedValues, [field]: value });
  };

  const goBackToChat = () => {
    navigate('/chat');
  };

  const saveChanges = async () => {
    try {
      if (userData.docId) {
        const userRef = doc(db, 'users', userData.docId);
        await updateDoc(userRef, editedValues);
        setEditMode({});
        setUserData({ ...userData, ...editedValues });
        console.log("Updated Successfully")
      } else {
        console.log('Document ID is not available');
      }
    } catch (error) {
      console.error('Failed to save changes:', error);
    }
  };

  const handleChangePassword = async () => {
    const auth = getAuth();
    const user = auth.currentUser;
    try {
      await updatePassword(user, newPassword);
      alert('Password updated successfully');
      setChangePasswordMode(false);
    } catch (error) {
      console.error('Error changing password:', error);
      alert('Failed to update password');
    }
  };

  const renderChangePassword = () => (
    changePasswordMode ? (
      <div className="flex flex-col mt-4 space-y-2">
        <input
          type="password"
          placeholder="New password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          className="p-2 border border-gray-300 rounded-md"
        />
        <div className="flex space-x-2">
          <button 
            onClick={handleChangePassword}
            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
          >
            Confirm
          </button>
          <button 
            onClick={() => setChangePasswordMode(false)}
            className="px-4 py-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400"
          >
            Cancel
          </button>
        </div>
      </div>
    ) : (
      <button 
        className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 mt-4"
        onClick={() => setChangePasswordMode(true)}
      >
        Change Password
      </button>
    )
  );

  const handleLogout = async () => {
    try {
      await signOut(getAuth());
      navigate('/login');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const renderEditableInput = (field, value) => (
    <div className="flex flex-col mt-2 space-y-2">
      <input
        type="text"
        className="p-2 border border-gray-300 rounded-md"
        value={editedValues[field]}
        onChange={(e) => handleInputChange(field, e.target.value)}
      />
      <button 
        onClick={saveChanges} 
        className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600"
      >
        Save
      </button>
    </div>
  );

  const renderField = (field, value) => (
    editMode[field] ? 
      renderEditableInput(field, value) : (
      <div className="flex justify-between items-center mt-4">
        <span className="text-gray-700">{value || 'Not set'}</span>
        <FontAwesomeIcon 
          icon={faPencilAlt} 
          onClick={() => toggleEdit(field)} 
          className="text-gray-500 hover:text-gray-700 cursor-pointer"
        />
      </div>
    )
  );

  return (
    <div className="max-w-lg mx-auto p-6 bg-white shadow-md rounded-lg mt-10">
      <button 
        className="flex items-center text-gray-600 hover:text-gray-800"
        onClick={goBackToChat}
      >
        <FontAwesomeIcon icon={faArrowLeft} className="mr-2" /> Back
      </button>
      <h2 className="text-2xl font-semibold text-center text-gray-800 mt-4">Account Management</h2>
      <div className="mt-6 space-y-6">
        <div className="flex flex-col">
          <label className="text-gray-600">First Name:</label>
          {renderField('firstName', userData.firstName)}
        </div>
        <div className="flex flex-col">
          <label className="text-gray-600">Last Name:</label>
          {renderField('lastName', userData.lastName)}
        </div>
        <div className="flex flex-col">
          <label className="text-gray-600">Company:</label>
          {renderField('company', userData.company)}
        </div>
        <div className="flex flex-col">
          <label className="text-gray-600">Industry:</label>
          {renderField('industry', userData.industry)}
        </div>
        <div className="flex flex-col">
          <label className="text-gray-600">Employees:</label>
          {renderField('employees', userData.employees)}
        </div>
        <div className="flex flex-col">
          <label className="text-gray-600">Revenue:</label>
          {renderField('revenue', userData.revenue)}
        </div>
        <div className="flex flex-col">
          <label className="text-gray-600">Phone:</label>
          {renderField('phone', userData.phone)}
        </div>
      </div>
      <div className="mt-6 flex flex-col space-y-4">
        {renderChangePassword()}
        <button 
          className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600"
          onClick={handleLogout}
        >
          Logout
        </button>
      </div>
    </div>
  );
};

export default Account;