// Import necessary functions from Firebase if using Firebase Functions
import { getApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';

export const getStripeManagementLink = async () => {
  const app = getApp();
  const functions = getFunctions(app, 'us-central1');
  const createPortalLink = httpsCallable(functions, 'ext-firestore-stripe-payments-createPortalLink');

  try {
    const { data } = await createPortalLink({ 
      returnUrl: window.location.origin,
      locale: 'auto' // Optional, defaults to 'auto'
    });
    return data.url;
  } catch (error) {
    console.error('Error getting Stripe management link:', error);
    throw error;
  }
};
