import React, { useState, useEffect, useContext } from 'react';
import { db } from './firebaseConfig'; 
import { AuthContext } from './AuthContext';
import { useNavigate } from 'react-router-dom';
import { collection, query, where, getDocs, addDoc, onSnapshot } from 'firebase/firestore';
import { getAuth, signOut } from 'firebase/auth';
import checkForActiveSubscription from './utilities/SubscriptionChecker';

const Subscriptions = () => {
  const [products, setProducts] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const auth = getAuth();

  const formatPrice = (amount, currency) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency,
    }).format(amount / 100);
  };

  const logout = () => {
    signOut(auth).then(() => {
      navigate('/');
    }).catch((error) => {
      console.error('Logout Error:', error);
    });
  };

  useEffect(() => {
    if (!currentUser) {
      navigate('/Welcome');
      return;
    }

    checkForActiveSubscription(currentUser.uid)
      .then(activeSubscriptions => {
        if (activeSubscriptions.length > 0) {
          console.log('User has active subscriptions:', activeSubscriptions);
          navigate('/chat');
        } else {
          console.log('User does not have active subscriptions.');
        }
      })
      .catch(error => {
        console.error('Error checking for active subscriptions:', error);
      });

    const fetchProducts = async () => {
      setLoading(true);
      try {
        const productsQuery = query(collection(db, 'products'), where('active', '==', true));
        const querySnapshot = await getDocs(productsQuery);

        const productsPromises = querySnapshot.docs.map(async (productDoc) => {
          let productInfo = productDoc.data();
          productInfo.id = productDoc.id;

          const pricesCollection = collection(productDoc.ref, 'prices');
          const priceQuerySnapshot = await getDocs(pricesCollection);

          if (priceQuerySnapshot.docs.length > 0) {
            const priceDoc = priceQuerySnapshot.docs[0];
            productInfo['priceId'] = priceDoc.id;
            productInfo['priceInfo'] = priceDoc.data();
            return productInfo;
          } 
          return null;
        });

        const productsData = (await Promise.all(productsPromises)).filter(product => product !== null);
        setProducts(productsData);
      } catch (err) {
        console.error('Error fetching products:', err);
        setError('Error fetching products.');
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [currentUser, navigate]);

  const startSubscription = async (priceId) => {
    setLoading(true);
    try {
      const checkoutSessionRef = await addDoc(collection(db, 'customers', currentUser.uid, 'checkout_sessions'), {
        price: priceId,
        allow_promotion_codes: true,
        success_url: window.location.origin,
        cancel_url: window.location.origin,
      });

      onSnapshot(checkoutSessionRef, (snap) => {
        const { error, url } = snap.data();
        if (error) {
          setError(`An error occurred: ${error.message}`);
          setLoading(false);
        }
        if (url) {
          window.location.assign(url);
        }
      });
    } catch (err) {
      console.error('Error starting subscription:', err);
      setError('Failed to start subscription.');
      setLoading(false);
    }
  };

  if (loading) {
    return <div className="flex justify-center items-center h-screen text-xl font-semibold text-gray-700">Loading...</div>;
  }

  if (error) {
    return <div className="flex justify-center items-center h-screen text-red-500 text-xl font-semibold">{error}</div>;
  }

  return (
    <div className="max-w-3xl mx-auto p-6 bg-white shadow-lg rounded-lg mt-10">
      <h1 className="text-3xl font-bold text-gray-800 mb-6 text-center">Choose Your Subscription</h1>
      <p className="text-center text-gray-600 mb-8">If you have any questions, feel free to contact us at <a href="mailto:support@taxbot.ie" className="text-blue-500 hover:text-blue-700">support@taxbot.ie</a>.</p>
      <div className="flex justify-center">
        <div className="grid grid-cols-1 gap-6">
          {products.map((product) => (
            <div key={product.id} className="p-6 border border-gray-200 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-300">
              <h3 className="text-xl font-semibold text-gray-800 mb-4">{product.name}</h3>
              <ul className="list-disc pl-5 text-gray-700 space-y-2">
                <li>Access to exclusive content</li>
                <li>Priority customer support</li>
                <li>Monthly webinars with experts</li>
                <li>Advanced analytics tools</li>
                <li>Free resources and guides</li>
              </ul>
              {product.priceInfo && (
                <p className="text-gray-800 mt-4">Price: {formatPrice(product.priceInfo.unit_amount, product.priceInfo.currency)}</p> 
              )}
              {product.priceId && (
                <button 
                  className="mt-6 w-full px-4 py-2 bg-blue-500 text-white font-semibold rounded-md hover:bg-blue-600 transition duration-300"
                  onClick={() => startSubscription(product.priceId)}
                >
                  Subscribe
                </button>
              )}
            </div>
          ))}
        </div>
      </div>
      <button 
        className="mt-10 w-full px-4 py-2 bg-red-500 text-white font-semibold rounded-md hover:bg-red-600 transition duration-300"
        onClick={logout}
      >
        Logout
      </button>
    </div>
  );
};

export default Subscriptions;