import React from 'react';
import { Slot } from '@radix-ui/react-slot';
import clsx from 'clsx';

// Define button styles using Tailwind with night mode support
const buttonVariants = {
  default: 'bg-primary text-white hover:bg-primary/90 dark:bg-gray-700 dark:text-white',
  secondary: 'bg-secondary text-white hover:bg-secondary/90 dark:bg-gray-500 dark:text-white',
  outline: 'border border-gray-300 text-gray-700 hover:bg-gray-100 dark:border-gray-500 dark:text-white',
  link: 'text-primary underline hover:no-underline dark:text-white',
};

const buttonSizes = {
  default: 'h-9 px-4 py-2',
  sm: 'h-8 px-3',
  lg: 'h-10 px-8',
  icon: 'h-9 w-9',
};

const Button = React.forwardRef(({ variant = 'default', size = 'default', asChild = false, className, ...props }, ref) => {
  const Comp = asChild ? Slot : 'button';
  return (
    <Comp
      ref={ref}
      className={clsx(buttonVariants[variant], buttonSizes[size], 'rounded-md font-medium transition', className)}
      {...props}
    />
  );
});

Button.displayName = 'Button';

export { Button };