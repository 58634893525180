import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import checkForActiveSubscription from './utilities/SubscriptionChecker';
import { getStripeManagementLink } from './utilities/StripeUtilities';
import { doc, getDoc } from 'firebase/firestore';
import { db } from './firebaseConfig';

const ManageSubscription = () => {
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);
  const [planDetails, setPlanDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!currentUser) {
      navigate('/login');
      return;
    }

    const fetchSubscriptionDetails = async () => {
      try {
        const activeSubscriptions = await checkForActiveSubscription(currentUser.uid);
        if (activeSubscriptions.length > 0) {
          const subscriptionData = activeSubscriptions[0];
          const stripeLink = await getStripeManagementLink(currentUser.uid);
          setSubscriptionDetails({ ...subscriptionData, stripeLink });
        } else {
          navigate('/Subscriptions');
        }
      } catch (error) {
        console.error('Error fetching subscription details:', error);
      } finally {
        setLoading(false);
      }
    };

    const fetchPlanDetails = async () => {
      try {
        const userRef = doc(db, 'users', currentUser.uid);
        const userSnap = await getDoc(userRef);

        if (userSnap.exists()) {
          setPlanDetails(userSnap.data().plan);
        } else {
          console.log("No such document!");
          setPlanDetails(null);
        }
      } catch (error) {
        console.error('Error fetching plan details:', error);
      }
    };

    fetchPlanDetails();
    fetchSubscriptionDetails();
  }, [currentUser, navigate]);

  if (loading) {
    return <div className="flex justify-center items-center h-screen text-xl font-semibold text-gray-700">Loading...</div>;
  }

  return (
    <div className="max-w-3xl mx-auto p-6 bg-white shadow-lg rounded-lg mt-10">
      <button 
        className="text-blue-500 hover:text-blue-700 mb-6 flex items-center"
        onClick={() => navigate('/Chat')}
      >
        &#8592; Back
      </button>
      <h1 className="text-3xl font-bold text-gray-800 mb-6 text-center">Manage Your Subscription</h1>
      {subscriptionDetails ? (
        <div className="p-4 border border-gray-200 rounded-lg shadow-sm">
          <h3 className="text-xl font-semibold text-gray-800 mb-4">{planDetails?.name || 'Your Plan'}</h3>
          <ul className="list-none pl-5 text-gray-700 space-y-2"> {/* Changed list-disc to list-none */}
            <li><strong>Subscription ID:</strong> {subscriptionDetails.id}</li>
            <li><strong>Start Date:</strong> {subscriptionDetails.startDate}</li>
            <li><strong>Renewal Date:</strong> {subscriptionDetails.endDate}</li>
            {/* Add more plan details here as needed */}
          </ul>
          <a 
            href={subscriptionDetails.stripeLink} 
            target="_blank" 
            rel="noopener noreferrer" 
            className="mt-6 inline-block px-6 py-2 bg-blue-500 text-white font-semibold rounded-md hover:bg-blue-600 transition duration-300"
          >
            Manage Subscription on Stripe
          </a>
        </div>
      ) : (
        <p className="text-center text-gray-600">You do not have an active subscription.</p>
      )}
    </div>
  );
};

export default ManageSubscription;