// src/SignupProcessor.js

import { app } from '../firebaseConfig'; // Ensure you are exporting 'app' from firebaseConfig.js
import { getFirestore, collection, addDoc } from 'firebase/firestore';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';

const db = getFirestore(app); // Initialize Firestore using the Firebase app
const auth = getAuth(app); // Initialize Firebase Authentication

const createUser = async (email, password, userData) => {
  try {
    // Create the user with email and password
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;

    // Add additional user data to Firestore
    await addDoc(collection(db, 'users'), {
      uid: user.uid,
      ...userData
    });

    console.log('User created with UID:', user.uid);
    return user;
  } catch (error) {
    console.error('Error creating user:', error.message);
    throw error;
  }
};

export { createUser, auth };
