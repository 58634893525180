import React from 'react';
import './styles/App.css';
import AppNavigator from './AppNavigator'; // Import AppNavigator
import { AuthProvider } from './AuthContext';

function App() {
  return (
    <AuthProvider>
    <div className="App">
      <AppNavigator /> {/* Use AppNavigator */}
    </div>
    </AuthProvider>
  );
}

export default App;
