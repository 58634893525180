import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';

const Welcome = () => {
  const { currentUser } = useContext(AuthContext);
  let navigate = useNavigate();

  useEffect(() => {
    if (currentUser) {
      navigate('/chat');
    }
  }, [currentUser, navigate]);

  return (
    <div className="min-h-screen bg-base-200 flex items-center justify-center">
      <div className="text-center">
        <h1 className="text-4xl font-bold text-base-content mb-4">Welcome to TaxBot!</h1>
        <p className="text-lg text-base-content mb-6">Signup or login below</p>
        <div className="flex justify-center space-x-4">
          <button
            className="btn btn-primary"
            onClick={() => navigate('/login')}
          >
            Login
          </button>
          <button
            className="btn btn-secondary"
            onClick={() => navigate('/signup')}
          >
            Signup
          </button>
        </div>
      </div>
    </div>
  );
};

export default Welcome;