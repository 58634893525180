import React, { useEffect, useState, useContext } from 'react';
import { db } from './firebaseConfig';
import { AuthContext } from './AuthContext';
import { collection, query, where, onSnapshot, orderBy, doc, deleteDoc, updateDoc } from 'firebase/firestore';
import './styles/Sidebar.css';

// Define DeleteConfirmationPopup before Sidebar component
const DeleteConfirmationPopup = ({ onConfirm, onCancel }) => {
  return (
    <div className="delete-popup">
      <p>Are you sure you want to delete this chat?</p>
      <button onClick={onConfirm}>Delete</button>
      <button onClick={onCancel}>Cancel</button>
    </div>
  );
};

const EditTitlePopup = ({ onSave, onCancel, thread }) => {
  const [localTitle, setLocalTitle] = useState(thread.title);

  return (
    <div className="edit-popup">
      <input
        type="text"
        value={localTitle}
        onChange={(e) => setLocalTitle(e.target.value)}
      />
      <button onClick={() => onSave(thread.id, localTitle)}>Save</button>
      <button onClick={onCancel}>Cancel</button>
    </div>
  );
};

const Sidebar = ({ onSelectThread, selectedThreadId, onCreateNewThread }) => {
  const { currentUser } = useContext(AuthContext);
  const [threads, setThreads] = useState([]);
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const [menuVisible, setMenuVisible] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editingThread, setEditingThread] = useState({ id: null, title: '' });
  const [isDeleting, setIsDeleting] = useState(false);
  const [deletingThread, setDeletingThread] = useState(null);

  useEffect(() => {
    if (currentUser) {
      const threadsRef = collection(db, 'threads');
      const q = query(threadsRef, where('user_id', '==', currentUser.uid), orderBy('last_updated', 'desc'));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        setThreads(querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
      });
      return () => unsubscribe();
    }
  }, [currentUser]);

  const toggleSidebar = () => setIsSidebarVisible(prevState => !prevState);

  const handleDeleteThread = async (threadId) => {
    setDeletingThread({ id: threadId });
    setIsDeleting(true);
  };

  const updateThreadTitle = async (threadId, newTitle) => {
    const threadRef = doc(db, 'threads', threadId);
    await updateDoc(threadRef, { title: newTitle });
    setIsEditing(false);
  };

  return (
    <div>
      <button className="hamburger-menu" onClick={toggleSidebar}>
        ☰
      </button>

      <div className={`sidebar ${isSidebarVisible ? 'show-sidebar' : 'hide-sidebar'}`}>
        <div className="sidebar-header">
          <button onClick={toggleSidebar} className="close-sidebar">✖</button>
          <button onClick={onCreateNewThread}>New Thread</button>
        </div>
        <div className="thread-list">
          {threads.map((thread) => (
            <div key={thread.id} className={`thread-item ${thread.id === selectedThreadId ? 'selected' : ''}`}>
              <div onClick={() => onSelectThread(thread)}>
                <p>{thread.title || 'No Title'}</p>
              </div>
              <button className="menu-button" onClick={() => setMenuVisible(thread.id === menuVisible ? null : thread.id)}>⋮</button>
              {menuVisible === thread.id && (
                <div className="thread-menu">
                  <button className="update-thread" onClick={() => { setEditingThread({ id: thread.id, title: thread.title }); setIsEditing(true); }}>Edit Title</button>
                  {isEditing && editingThread.id === thread.id && <EditTitlePopup onSave={updateThreadTitle} onCancel={() => setIsEditing(false)} thread={editingThread} />}
                  <button className="delete-thread" onClick={() => handleDeleteThread(thread.id)}>Delete Thread</button>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      {isDeleting && (
        <DeleteConfirmationPopup
          onConfirm={async () => {
            await deleteDoc(doc(db, 'threads', deletingThread.id));
            setIsDeleting(false);
            setMenuVisible(null); // Close menu after deletion
          }}
          onCancel={() => {
            setIsDeleting(false);
          }}
        />
      )}
    </div>
  );
};

export default Sidebar;