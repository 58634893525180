import React, { useRef, useEffect } from 'react';
import '../styles/Chat.css'; 

const ChatInput = ({ onSendMessage, newMessage, setNewMessage, isSending }) => {
  const textareaRef = useRef(null);
  const containerRef = useRef(null);

  const animateHeight = (startHeight, endHeight) => {
    const duration = 200; // Duration of the animation in milliseconds
    let start = null;

    const step = (timestamp) => {
      if (!start) start = timestamp;
      const progress = Math.min((timestamp - start) / duration, 1);
      const currentHeight = progress * (endHeight - startHeight) + startHeight;
      textareaRef.current.style.height = `${currentHeight}px`;

      if (progress < 1) {
        requestAnimationFrame(step);
      }
    };

    requestAnimationFrame(step);
  };

  const adjustHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      const maxHeight = parseInt(getComputedStyle(textarea).maxHeight, 10);

      // Save the current height before resetting
      const currentHeight = textarea.offsetHeight;
      textarea.style.height = 'auto';
      const newHeight = Math.min(textarea.scrollHeight, maxHeight);

      if (newHeight < currentHeight) {
        // Animate only when the height is reducing
        animateHeight(currentHeight, newHeight);
      } else {
        // Immediately set to new height when increasing
        textarea.style.height = `${newHeight}px`;
      }
    }
  };

  // Effect to adjust height when newMessage changes
  useEffect(() => {
    adjustHeight();
  }, [newMessage]);

  useEffect(() => {
  const adjustForKeyboard = () => {
    if (window.innerWidth < 600) { // Adjust for mobile devices
      const newViewportHeight = window.innerHeight;
      // Adjust the bottom position of the chat input area based on the new viewport height
      // You might need to experiment with these values to get the right positioning
      containerRef.current.style.bottom = `${newViewportHeight - window.outerHeight}px`;
    }
  };

  window.addEventListener('resize', adjustForKeyboard);

  return () => {
    window.removeEventListener('resize', adjustForKeyboard);
  };
}, []);

  return (
    <div className="chat-input">
      <textarea
        ref={textareaRef}
        className="chat-input-textarea"
        value={newMessage}
        onChange={(e) => {
          setNewMessage(e.target.value);
          adjustHeight(); // Adjust height on input change
        }}
        onKeyPress={(e) => {
          if (e.key === 'Enter' && e.shiftKey) {
            e.preventDefault(); // Prevent the default action to avoid sending the message
            const value = newMessage;
            const selectionStart = e.target.selectionStart;
            const selectionEnd = e.target.selectionEnd;
            setNewMessage(value.substring(0, selectionStart) + "\n" + value.substring(selectionEnd));
            setTimeout(() => e.target.selectionStart = e.target.selectionEnd = selectionStart + 1, 0);
          } else if (e.key === 'Enter' && !isSending) {
            e.preventDefault(); // Prevent default to avoid form submission
            onSendMessage();
          }
        }}
        placeholder="Type a message..."
        disabled={isSending}
        rows={1}
      />
      <button onClick={onSendMessage} disabled={isSending}>Send</button>
    </div>
  );
};

export default ChatInput;