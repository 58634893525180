import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/style.css';
import { AuthContext } from '../AuthContext';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { app } from '../firebaseConfig';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    if (currentUser) {
      navigate('/chat');
    }
  }, [currentUser, navigate]);

  const goBack = () => {
    navigate('/');
  };

  const handleLogin = async () => {
    setError('');
    try {
      const auth = getAuth(app);
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/chat');
    } catch (error) {
      setError(`Login failed: ${error.message}`);
    }
  };

  // Handles "Enter" key press
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleLogin();
    }
  };

  const navigateToResetPassword = () => {
    navigate('/password-reset');
  };

  return (
    <div className="min-h-screen bg-base-200 flex items-center justify-center">
      <div className="card w-96 bg-base-100 shadow-xl">
        <div className="card-body relative">
          {/* Flex container for back button and title */}
          <div className="flex items-center justify-center relative mb-4">
            <button className="btn btn-circle btn-ghost absolute -left-6 text-base-content" onClick={goBack}>
              ←
            </button>

            <h2 className="card-title text-base-content">Login to TaxBot</h2>
          </div>

          {error && <div className="alert alert-error mb-4">{error}</div>}

          <input
            type="email"
            placeholder="Email Address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="input input-bordered w-full mb-4 text-base-content"
            required
            onKeyPress={handleKeyPress}
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="input input-bordered w-full mb-4 text-base-content"
            required
            onKeyPress={handleKeyPress}
          />

          <button onClick={handleLogin} className="btn btn-primary w-full mb-4">Login</button>

          <div className="text-center">
            <a className="link link-primary" onClick={navigateToResetPassword}>Forgot password?</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
