import React from 'react';
import Navbar from './HomeComponents/Navbar';
import Footer from './HomeComponents/Footer';
import './styles/tailwind-home.css';
import './styles/global-home.css';

const Contact = () => {
  return (
    <div className="light">  {/* Force light mode */}
      {/* Navbar */}
      <Navbar />

      {/* Main Section */}
      <div className="border-b border-border pb-12">
        <main className="container mx-auto">
          <div className="relative md:mt-12 mx-auto w-full max-w-4xl text-center">
            <h1 className="md:text-6xl my-4 font-extrabold text-3xl md:leading-tight text-black dark:text-white">
              Contact Us
            </h1>
            <p className="mx-auto my-4 text-sm w-full max-w-xl text-center font-medium leading-relaxed tracking-wide text-gray-700 dark:text-gray-300">
              We'd love to hear from you! Please fill out the form below, and we'll get in touch as soon as possible.
            </p>

            {/* Netlify Form */}
            <form
              name="contact"
              netlify
              method="POST"
              data-netlify="true"
              className="flex flex-col gap-6 max-w-2xl mx-auto p-6 bg-white dark:bg-gray-800 rounded-lg shadow-md pb-12"
            >
              <input type="hidden" name="form-name" value="contact" />
              
              {/* Name Field */}
              <div className="flex flex-col">
                <label className="text-left font-semibold text-black dark:text-white" htmlFor="name">Your Name</label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  required
                  className="mt-2 p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                  placeholder="Enter your name"
                />
              </div>

              {/* Email Field */}
              <div className="flex flex-col">
                <label className="text-left font-semibold text-black dark:text-white" htmlFor="email">Email</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  required
                  className="mt-2 p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                  placeholder="Enter your email"
                />
              </div>

              {/* Message Field */}
              <div className="flex flex-col">
                <label className="text-left font-semibold text-black dark:text-white" htmlFor="message">Message</label>
                <textarea
                  name="message"
                  id="message"
                  rows="5"
                  required
                  className="mt-2 p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:text-white light: text-black"
                  placeholder="Enter your message"
                ></textarea>
              </div>

              {/* Submit Button */}
              <button
                type="submit"
                className="bg-green-600 hover:bg-green-500 light:text-black dark:text-white py-3 rounded-lg font-semibold transition-all"
              >
                Send Message
              </button>
            </form>
          </div>
        </main>
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default Contact;