import React, { useEffect, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeExternalLinks from 'rehype-external-links';
import '../styles/Chat.css';

const MessageList = ({ messages, isSending }) => {
  const messagesEndRef = useRef(null);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  // Function to add extra line breaks
  const addExtraLineBreaks = (text) => {
    return text.replace(/\n\n/g, '\n\n\n');
  };

  return (
    <div className="chat-messages">
      {messages.map((message, index) => (
        <div key={index} className={`message ${message.sender === "user" ? "user" : "system"}`}>
          <ReactMarkdown
            children={addExtraLineBreaks(message.text)}
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[
              [rehypeExternalLinks, { target: '_blank', rel: ['noopener', 'noreferrer'] }]
            ]}
            components={{
              a: ({ node, ...props }) => <a className="text-blue-500 hover:underline" {...props} />, // Style links as blue with underline on hover
              p: ({ node, ...props }) => <p {...props} />, // Ensure paragraphs render correctly
              h1: ({ node, ...props }) => <p><h1 {...props} /></p>,
              h2: ({ node, ...props }) => <p><h2 {...props} /></p>,
              h3: ({ node, ...props }) => <p><h3 {...props} /></p>,
              h4: ({ node, ...props }) => <p><h4 {...props} /></p>,
              h5: ({ node, ...props }) => <p><h5 {...props} /></p>,
              h6: ({ node, ...props }) => <p><h6 {...props} /></p>,
              ul: ({ node, ...props }) => <p><ul {...props} /></p>,
              ol: ({ node, ...props }) => <p><ol {...props} /></p>,
              li: ({ node, ...props }) => <li {...props} />,
              blockquote: ({ node, ...props }) => <p><blockquote {...props} /></p>,
              code: ({ node, ...props }) => <p><code {...props} /></p>,
            }}
          />
        </div>
      ))}
      {isSending && (
        <div className="message system">
          <span className="loading-dots">
            <div></div><div></div><div></div>
          </span>
        </div>
      )}
      <div ref={messagesEndRef} />
    </div>
  );
};

export default MessageList;