import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { app } from '../firebaseConfig';

const PasswordReset = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const navigate = useNavigate();

  const handlePasswordReset = async () => {
    setMessage('');
    try {
      const auth = getAuth(app);
      await sendPasswordResetEmail(auth, email);
      setMessage('Check your email for the password reset link.');
      setEmailSent(true); // Set the flag to true when email is sent
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    }
  };

  return (
    <div className="min-h-screen bg-base-200 flex items-center justify-center">
      <div className="card w-96 bg-base-100 shadow-xl">
        <div className="card-body relative">
          {/* Flex container for back button and title */}
          <div className="flex items-center justify-center relative mb-4"> {/* Added mb-6 for padding below */}
            {/* Back button with left adjustment */}
            <button
              className="btn btn-circle btn-ghost absolute -left-6 text-base-content"
              onClick={() => navigate('/login')}
            >
              ←
            </button>

            {/* Title stays centered */}
            <h2 className="card-title text-base-content">Reset Password</h2>
          </div>

          {message && (
            <div
              className={`text-center p-4 mb-4 rounded ${
                emailSent
                  ? 'bg-green-100 text-green-700 dark:bg-green-900 dark:text-green-200'
                  : 'bg-red-100 text-red-700 dark:bg-red-900 dark:text-red-200'
              }`}
            >
              {message}
            </div>
          )}
          {!emailSent && (
            <>
              <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="input input-bordered w-full mb-4 text-base-content"
                required
              />
              <button onClick={handlePasswordReset} className="btn btn-primary w-full">
                Send Reset Email
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PasswordReset;