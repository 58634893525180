import React, { useState } from 'react';
import '../styles/style.css';

const StepOne = ({ nextStep, handleChange, values, onKeyPress }) => {
  const [error, setError] = useState('');

  const handleNextClick = () => {
    if (!values.firstName || !values.lastName || !values.email || !values.password) {
      setError('Please fill out all fields.');
    } else {
      setError('');
      nextStep();
    }
  };

  return (
    <>
      {error && <div className="alert alert-error mb-4">{error}</div>}
      <div className="flex flex-col gap-4">
        <input
          type="text"
          placeholder="First Name *"
          onChange={handleChange('firstName')}
          value={values.firstName}
          className="input input-bordered w-full text-base-content"
          required
          onKeyPress={onKeyPress}
        />
        <input
          type="text"
          placeholder="Last Name *"
          onChange={handleChange('lastName')}
          value={values.lastName}
          className="input input-bordered w-full text-base-content"
          required
          onKeyPress={onKeyPress}
        />
        <input
          type="email"
          placeholder="Email Address *"
          onChange={handleChange('email')}
          value={values.email}
          className="input input-bordered w-full text-base-content"
          required
          onKeyPress={onKeyPress}
        />
        <input
          type="password"
          placeholder="Password *"
          onChange={handleChange('password')}
          value={values.password}
          className="input input-bordered w-full text-base-content"
          required
          onKeyPress={onKeyPress}
        />
        <button onClick={handleNextClick} className="btn btn-primary w-full">Next</button>
      </div>
    </>
  );
};

export default StepOne;