// src/utilities/SubscriptionChecker.js

import { db } from '../firebaseConfig';
import { collection, query, where, getDocs } from 'firebase/firestore';

const checkForActiveSubscription = async (userId) => {
  const subscriptionsRef = collection(db, 'customers', userId, 'subscriptions');
  const activeSubscriptionsQuery = query(subscriptionsRef, where('status', '==', 'active'));
  const querySnapshot = await getDocs(activeSubscriptionsQuery);

  if (!querySnapshot.empty) {
    const activeSubscriptions = [];
    querySnapshot.forEach(doc => {
      const subscriptionData = doc.data();
      if (subscriptionData.status === 'active') {
        const startDate = new Date(subscriptionData.current_period_start.seconds * 1000);
        const endDate = new Date(subscriptionData.current_period_end.seconds * 1000);
        activeSubscriptions.push({
          id: doc.id,
          startDate: startDate.toDateString(),
          endDate: endDate.toDateString(),
        });
      }
    });
    return activeSubscriptions;
  } else {
    return [];
  }
};

export default checkForActiveSubscription;
