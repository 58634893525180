import '../styles/Chat.css'; 

const AlertMessage = ({ message, onClose }) => (
  <div className="alert-message">
    <p>{message}</p>
    <button onClick={onClose}>OK</button>
  </div>
);

export default AlertMessage;
