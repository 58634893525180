import { Handshake } from 'lucide-react';
import { RocketIcon, Pencil2Icon } from '@radix-ui/react-icons';

export const features = [
  {
    title: 'Fast',
    description: 'Get answers in seconds, instead of days.',
    icon: <RocketIcon className="w-6 h-6" />, // Using Radix UI icons
  },
  {
    title: 'Personalised',
    description: 'Advice that’s tailored to your specific situation.',
    icon: <Pencil2Icon className="w-6 h-6" />, // Radix UI icon
  },
  {
    title: 'Reliable',
    description: 'All responses cite current Irish tax legislation.',
    icon: <Handshake className="w-6 h-6" />, // Lucide icon
  },
];