import React, { useState, useContext, useEffect } from 'react';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import { createUser } from './SignupProcessor';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../AuthContext';
import '../styles/style.css';

const Signup = () => {
  const [step, setStep] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    company: '',
    industry: '',
    employees: '',
    marketingConsent: false,
    revenue: ''
  });

  useEffect(() => {
    if (currentUser) {
      navigate('/chat');
    }
  }, [currentUser, navigate]);

  const nextStep = () => setStep(step + 1);
  const prevStep = () => setStep(step - 1);

  const handleChange = input => e => {
    setFormData({ ...formData, [input]: e.target.value });
  };

  const goBack = () => {
    navigate('/');
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      if (!Object.values(formData).every(value => typeof value === 'string' ? value.trim() !== '' : true)) {
        throw new Error('Please fill out all fields.');
      }

      await createUser(formData.email, formData.password, {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        company: formData.company,
        industry: formData.industry,
        employees: formData.employees,
        revenue: formData.revenue,
        marketingConsent: formData.marketingConsent
      });
      navigate('/chat');
    } catch (error) {
      console.error('Signup error:', error);
      alert(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  // Handles "Enter" key press on StepOne and StepTwo
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      if (step === 1) {
        nextStep();
      } else {
        handleSubmit();
      }
    }
  };

  if (isSubmitting) {
    return (
      <div className="min-h-screen bg-base-200 flex items-center justify-center">
        <div className="card w-96 bg-base-100 shadow-xl">
          <div className="card-body items-center text-center">
            <h2 className="card-title text-base-content">Creating your account...</h2>
            <progress className="progress w-56"></progress>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-base-200 flex items-center justify-center">
      <div className="card w-96 bg-base-100 shadow-xl">
        <div className="card-body relative">
          {/* Back button and title alignment */}
          <div className="flex items-center justify-center relative mb-2">
            <button
              className="btn btn-circle btn-ghost absolute -left-6 text-base-content"
              onClick={goBack}
            >
              ←
            </button>
            <h2 className="card-title text-base-content">
              {step === 1 ? "Sign Up - Your Details" : "Sign Up - Additional Details"}
            </h2>
          </div>

          {/* Conditional rendering of steps with keypress handling */}
          {step === 1 && (
            <StepOne nextStep={nextStep} handleChange={handleChange} values={formData} onKeyPress={handleKeyPress} />
          )}
          {step === 2 && (
            <StepTwo prevStep={prevStep} handleChange={handleChange} handleSubmit={handleSubmit} values={formData} onKeyPress={handleKeyPress} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Signup;