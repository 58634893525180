import React, { useState, useContext, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import '../styles/Chat.css'; 

const DropdownMenu = ({ showDropdown, toggleDropdown, navigate, handleLogout }) => {
  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      toggleDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className="account-menu">
      <FontAwesomeIcon icon={faUserCircle} onClick={() => toggleDropdown(!showDropdown)} className="account-icon" />
      {showDropdown && (
        <div className="dropdown-menu" ref={dropdownRef}>
          <div onClick={() => navigate('/account')}>Account</div>
          <div onClick={() => navigate('/manage-subscription')}>Manage Subscription</div>
          <div onClick={handleLogout}>Logout</div>
        </div>
      )}
    </div>
  );
};

export default DropdownMenu;
